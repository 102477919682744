import {
  Menu, MenuItem,
} from '@mui/material';
import useAxiosGet from 'hooks/useAxiosGet.hook';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SupersetMenuProps } from './Superset.types';

const SupersetMenu: React.FunctionComponent<SupersetMenuProps> = (props) => {
  const {
    anchorEl, setReport, setAnchorElInsights,
  } = props;
  const navigate = useNavigate();

  const handleCloseInsights = (value: string):void => {
    setReport(value);
    setAnchorElInsights(null);
    navigate('/insights');
  };

  const justClose = ():void => {
    setAnchorElInsights(null);
  };

  const styles = {
    link: {
      textDecoration: 'none',
      color: 'inherit',
    },
  };

  type AnalyticNavication = {
    navigation_first_level_label: string
    url_path: string
  };

  const url = '/getAnalyticNavigation';
  const {
    loading, data,
  } = useAxiosGet<AnalyticNavication[]>(url, [url]);

  const renderMenuOptions = (): JSX.Element[] | undefined => {
    if (loading) return undefined;
    return data?.map((nav) => <MenuItem key={nav.url_path} sx={styles.link} onClick={() => handleCloseInsights(nav.url_path)}>{nav.navigation_first_level_label}</MenuItem>);
  };

  return (
    <>
      <Menu
        id="insight-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={justClose}
      >
        {renderMenuOptions()}
      </Menu>
    </>
  );
};

export default SupersetMenu;
