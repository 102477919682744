import { getConfig } from 'api/util/getConfig';
import OliAxios from 'api/util/OliAxios';
import { AxiosResponse } from 'axios';
import { FillResponse } from './DocPrep.types';

const mergePDF = async (token: string | undefined, params: unknown): Promise<AxiosResponse<FillResponse> | undefined> => {
  if (!token) return undefined;
  const config = getConfig(token);
  const url = '/documentmanagement/fillpdfform';
  return OliAxios.get(url, {
    ...config, params,
  });
};

const approvePDF = async (token: string | undefined, params: unknown): Promise<AxiosResponse<FillResponse> | undefined> => {
  if (!token) return undefined;
  const config = getConfig(token);
  const url = '/documentmanagement/approve';
  return OliAxios.get(url, {
    ...config, params,
  });
};

const rejectPDF = async (token: string | undefined, params: unknown): Promise<AxiosResponse<FillResponse> | undefined> => {
  if (!token) return undefined;
  const config = getConfig(token);
  const url = '/documentmanagement/reject';
  return OliAxios.get(url, {
    ...config, params,
  });
};

const deletePDF = async (token: string | undefined, params: unknown): Promise<AxiosResponse<unknown> | undefined> => {
  if (!token) return undefined;
  const config = getConfig(token);
  const url = '/documentmanagement/delete';
  return OliAxios.get(url, {
    ...config, params,
  });
};

export {
  mergePDF, approvePDF, rejectPDF, deletePDF,
};
