import React from 'react';
import {
  useStyles,
} from './Iframe.jss';
import { IIframeProps } from './Iframe.types';

const Iframe: React.FunctionComponent<IIframeProps> = (props) => {
  const {
    title, src,
  } = props;

  const classes = useStyles;

  return (
    <iframe title={title} src={src} style={classes} />
  );
};

export default Iframe;
