import OliAxios from 'api/util/OliAxios';
import UserContext from 'context/UserContext';
import {
  useState, useEffect, useContext,
} from 'react';

interface IResult<T> {
  loading:boolean,
  data?: T,
  error?: Error,
}

function useAxiosGet<T = unknown>(uri?: string, dependencyArray: Array<unknown> = [], body: unknown = {}): IResult<T> {
  const userContext = useContext(UserContext);
  const [data, setData] = useState<T | undefined>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [retryCount, setRetryCount] = useState<number>(0);

  const config = {
    headers: {
      Authorization: userContext?.token,
    },
    params: body,
    temp: 'hello',
  };

  const handleError = (e:Error):void => {
    if (retryCount < 2) {
      setRetryCount(retryCount + 1);
    } else {
      setLoading(false);
      setError(e);
    }
  };

  useEffect(() => {
    if (!uri) return;
    OliAxios.get<T>(uri, config)
      .then((response) => setData(response?.data))
      .then(() => setLoading(false))
      .catch((e) => { handleError(e); });
  }, [...dependencyArray, retryCount]);

  return {
    loading, data, error,
  };
}

export default useAxiosGet;
