import { getConfig } from 'api/util/getConfig';
import OliAxios from 'api/util/OliAxios';
import { GuestTokenResponse } from './Dashboards.types';

const fetchGuestToken = async (token: string | undefined): Promise<string> => {
  if (!token) return '';
  const config = getConfig(token);
  const url = '/dashboards/auth';
  const resp: GuestTokenResponse = await OliAxios.get(url, config);
  return resp.data.token;
};

export {
  fetchGuestToken,
};
