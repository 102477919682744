import Iframe from 'components/Iframe/Iframe';
import React, {
  useContext,
} from 'react';
import {
  encode, TAlgorithm,
} from 'jwt-simple';
import UserContext from 'context/UserContext';
import {
  SupersetProps,
} from './Superset.types';

const Superset: React.FunctionComponent<SupersetProps> = (props) => {
  const { report } = props;

  const algorithm: TAlgorithm = 'HS512';
  const supersetUser = useContext(UserContext)?.supersetUser;
  const token:string = encode({ user_name: supersetUser }, 'sssshhhhh', algorithm);
  const supersetprefix = `https://${process.env.REACT_APP_SUPERSET_URL}`;
  const prefix = `${supersetprefix}/superset/dashboard`;
  const login = `${supersetprefix}/login`;
  const suffix = '?preselect_filters=%7B%7D%26standalone=true%26native_filters=%28%29';

  const buildUrl = (dashboard:string):string => `${login}?token=${token}&next=${prefix}/${dashboard}/${suffix}`;

  return (
    <>
      <Iframe title="Superset Dashboard" src={buildUrl(report)} />
    </>
  );
};

export default Superset;
